import React, { Component } from "react";
import GatsbyLink from "../../GatsbyLink";
import "./RelatedPass.scss";
// import Carousel from "../../Carousel";
import PostListItem from "../../Blog/PostListItem";
import { PostListNavigation } from "../../Blog/PostListNavigation";

class ThisSlider extends Component {
  render() {
    const { posts, siteMetadata, pathPrefix, showAuthor, dateFormat } =
      this.props;
    const settings = {
      container: "related-carousel",
      nav: false,
      mouseDrag: true,
      controls: true,
      loop: false,
      gutter: 20,
      slideBy: 1,
      controlsContainer: ".related-nav",
      responsive: {
        1600: {
          items: 3,
        },
        1024: {
          items: 3,
        },
        640: {
          items: 2,
        },
        420: {
          items: 1,
        },
      },
    };

    return (
      <div className="slider">
        <div className="related-nav">
          <button type="button" title="Prev" className="prev" />
          <button type="button" title="Next" className="next" />
        </div>
        {/* <Carousel settings={settings}>
          {posts &&
            posts.map(
              (post, slide_count) =>
                post.node.slug !== "gatsby" && (
                  <PostListItem
                    key={slide_count}
                    data={post.node}
                    dateFormat={dateFormat}
                    siteMetadata={siteMetadata}
                    pathPrefix={pathPrefix}
                    showAuthor={showAuthor}
                  />
                )
            )}
        </Carousel> */}
      </div>
    );
  }
}

class ListView extends Component {
  render() {
    const { posts, siteMetadata, pathPrefix, showAuthor, dateFormat } =
      this.props;
    return (
      <div className="slider list-view">
        {posts &&
          posts.map(
            (post, slide_count) =>
              post.node.slug !== "gatsby" && (
                <PostListItem
                  key={slide_count}
                  data={post.node}
                  siteMetadata={siteMetadata}
                  pathPrefix={pathPrefix}
                  showAuthor={showAuthor}
                  dateFormat={dateFormat}
                />
              )
          )}
      </div>
    );
  }
}

export const RelatedPass = (props) => {
  const {
    data,
    title = "Related Posts",
    listView,
    contentType = "post",
    action = null,
  } = props;
  const {
    allWordpressPost,
    categoryFilter,
    allWordpressWpProjects,
    wordpressWpSettings,
    allWordpressCategory,
    siteSettings,
  } = data;
  const postPass = allWordpressWpProjects
    ? allWordpressWpProjects
    : allWordpressPost;
  const { showAuthor } = siteSettings.options;
  const { edges: posts } = postPass;
  const blogFeedLink = action
    ? action
    : { url: `/${wordpressWpSettings.blogSlug}/`, title: "View all" };
  const pathPrefix =
    contentType === "project" ? "projects" : wordpressWpSettings.blogSlug;
  return (
    <section className={`post-feed ${contentType}-list`}>
      {listView && (
        <PostListNavigation
          pathPrefix={wordpressWpSettings.blogSlug}
          categoryFilter={categoryFilter}
        />
      )}
      <div className="outer">
        <div className="wrap">
          <div className="inner">
            {!listView && title && (
              <h3
                className="title section-title"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}
            {!listView && (
              <ThisSlider
                posts={posts}
                siteMetadata={wordpressWpSettings}
                dateFormat={wordpressWpSettings.date_format}
                pathPrefix={pathPrefix}
                showAuthor={showAuthor}
              />
            )}
            {listView && (
              <ListView
                posts={posts}
                siteMetadata={wordpressWpSettings}
                dateFormat={wordpressWpSettings.date_format}
                pathPrefix={pathPrefix}
                showAuthor={showAuthor}
              />
            )}
            {blogFeedLink && (
              <GatsbyLink
                to={blogFeedLink.url}
                label={blogFeedLink.title}
                className="link"
              >
                {blogFeedLink.title}
              </GatsbyLink>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
